/* eslint-disable indent */
import { Suspense, forwardRef, type Ref, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { withMemo } from '@shared/components';

import { useTranslation } from 'react-i18next';
import { useStores } from '@shared/hooks';
import { InsurancePeriod } from '@entities/import-smart-components/insurance-period-component';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import {
  InsurancePeriodOptions,
  InsurancePeriodValues,
  InsurancePeriodAnalyticEvent,
} from '@pulse-smart-components-kit/insurance-period';

import { getInsurancePeriodSchema } from './schemas';
import { AnalyticEventsMap } from '@shared/types';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<InsurancePeriodAnalyticEvent> = {
  [InsurancePeriodAnalyticEvent.ON_ITEMS_SELECT]: {
    name: analyticEvents.sportSelected,
    isSingle: true,
  },
  [InsurancePeriodAnalyticEvent.ON_DATES_SELECT]: {
    name: analyticEvents.datesSelected,
  },
};

const MemoizedInsurancePeriod = withMemo(InsurancePeriod, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
]);

export const InsurancePeriodAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<InsurancePeriodValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const {
        MainStore: {
          initProductStore: {
            insuranceLimitStart: { startDate, endDate, maxDuration },
            initState: { sports, maxSportsCount },
          },
          productStore: {
            price: { promoCode, isApplyedPromo },
            formState: { InsurancePeriod: InsurancePeriodState },
          },
          authStore: { disableForm },
        },
      } = useStores();

      const formSchema = useMemo(
        () =>
          getInsurancePeriodSchema(startDate, maxDuration, maxSportsCount ?? 0),
        [startDate, maxDuration, maxSportsCount]
      );

      const fieldStateWithErrors = useMemo(() => {
        const isCoverageItemsError =
          isApplyedPromo &&
          promoCode &&
          !InsurancePeriodState?.coverageItems?.length;

        return {
          ...fieldState,
          ...(isCoverageItemsError
            ? {
                error: {
                  coverageItems: {
                    message: t('SMART:InsurancePeriod.errors.sportsRequired'),
                  },
                },
              }
            : {}),
        };
      }, [
        sports,
        promoCode,
        isApplyedPromo,
        InsurancePeriodState?.coverageItems,
      ]);

      const options: InsurancePeriodOptions = useMemo(
        () => ({
          coverageItems: sports,
          startDate,
          endDate,
          maxDuration,
        }),
        [sports, startDate, endDate, maxDuration]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<InsurancePeriodAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense>
          <MemoizedInsurancePeriod
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldStateWithErrors}
            formSchema={formSchema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

InsurancePeriodAdapter.displayName = 'InsurancePeriodAdapter';
