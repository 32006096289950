import { Suspense, forwardRef, useEffect, type Ref, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';

import { useStores } from '@shared/hooks';
import { NumberOfInsuredPersons } from '@entities/import-smart-components/number-of-insured-persons-component';
import {
  NumberOfInsuredPersonsOptions,
  NumberOfInsuredPersonsValues,
} from '@pulse-smart-components-kit/number-of-insured-persons';
import { InsuredPerson } from '@pulse-smart-components-kit/insured-persons';
import { getNumberOfInsuredPersonsSchema } from './schemas';
import { deletePerson } from './utils';
import { SmartComponentId } from '@shared/constants';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import { withMemo } from '@shared/components';

const MemoizedNumberOfInsuredPersons = withMemo(NumberOfInsuredPersons, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
]);

export const NumberOfInsuredPersonsAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
        options,
      }: SmartComponentAdapterProps<
        NumberOfInsuredPersonsValues,
        NumberOfInsuredPersonsOptions
      >,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
          productStore: { formState },
          applicationStore: { updateFormValue },
          initProductStore: { initState },
        },
      } = useStores();
      const maxCountInsured = initState?.maxObjectsNumber ?? options.maxCount;
      const formSchema = useMemo(
        () =>
          getNumberOfInsuredPersonsSchema(options.minCount, maxCountInsured),
        [options.minCount, maxCountInsured]
      );

      const onHandleChange = (data: NumberOfInsuredPersonsValues) => {
        const persons = formState?.InsuredPersons?.insuredPersons ?? [];
        const choosedPersonNumber = data.numberOfInsuredPersons ?? 1;

        if (persons.length > choosedPersonNumber) {
          const changedPersons = deletePerson(persons);
          updateFormValue('InsuredPersons', {
            ...value,
            insuredPersons: changedPersons,
          });
        } else if (persons.length < choosedPersonNumber) {
          const newPersons: InsuredPerson[] = [];
          newPersons.length = choosedPersonNumber - persons.length;

          for (let i = 0; i < newPersons.length; i++) {
            newPersons[i] = {
              firstName: '',
              surname: '',
              secondName: '',
              birthday: null,
              id: uuid(),
            };
          }

          updateFormValue('InsuredPersons', {
            ...value,
            insuredPersons: [...persons, ...newPersons],
          });
        }

        onChange(data);
      };

      const optionsWithMaxCount = useMemo(
        () => ({
          ...options,
          maxCount: maxCountInsured,
        }),
        [maxCountInsured, options]
      );

      useEffect(() => {
        updateFormValue(SmartComponentId.NUMBER_OF_INSURED_PERSONS, {
          ...value,
          isValid: true,
        });
      }, []);

      return (
        <Suspense>
          <MemoizedNumberOfInsuredPersons
            ref={forwardRef}
            value={value}
            onChange={onHandleChange}
            isSubmitting={isSubmitting}
            options={optionsWithMaxCount}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formSchema}
          />
        </Suspense>
      );
    }
  )
);

NumberOfInsuredPersonsAdapter.displayName = 'NumberOfInsuredPersonsAdapter';
